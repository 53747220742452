import styled from "styled-components";

const CustomizeContainer = styled.div`
  position: absolute;
  top: 15%;
  right: 0;
  width: ${({ open }) => (open ? "90px" : "70px")};
  height: 70px;

  z-index: 50;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;

  background: linear-gradient(
    45deg,
    rgba(256, 256, 256, 0.9) 50%,
    var(--primary-one),
    var(--primary-one-dark),
    var(--primary-two-dark)
  );
  background-size: 500%;
  background-position: ${({ open }) => (open ? "100%" : "0%")};
  fill: ${({ open }) => (open ? "var(--white)" : "var(--black)")};
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);

  transition: all 0.35s;
  transform: ${({ open }) => (open ? "translateX(-300px)" : "translateX(0)")};

  cursor: pointer;

  &:hover {
    width: 90px;
    background-position: 100%;
  }

  &:hover .customize-paint-icon {
    fill: var(--white);

    animation: ${({ open }) =>
      open ? "none" : "infinite paintWobble 1.5s ease-in-out"};
  }

  @keyframes paintWobble {
    0% {
      transform: rotate(0);
    }

    25% {
      transform: rotate(0);
    }

    37% {
      transform: rotate(7deg);
    }

    49% {
      transform: rotate(0deg);
    }

    61% {
      transform: rotate(7deg);
    }

    73% {
      transform: rotate(0deg);
    }
  }
`;

export default CustomizeContainer;
