import React, { useState } from "react";
import { Customize } from "./Customize";

const CustomizeShop = () => {
  const [customizeOpen, setCustomizeOpen] = useState(false);

  const onCustomizeClick = () => {
    setCustomizeOpen((prevState) => !prevState);
  };
  const closeCustomize = () => setCustomizeOpen(false);

  return (
    <Customize
      onClickHandler={onCustomizeClick}
      open={customizeOpen}
      closeCustomize={closeCustomize}
    />
  );
};

export default CustomizeShop;
